.b-outlets {
    &__heading {
        margin-bottom: 48px;
        text-align: center;
    }

    &__list {
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        display: flex;
        gap: space(l);

        @include screen-min(l) {
            gap: 40px;
        }
    }

    &__logo {
        height: 48px;

        @include screen-min(l) {
            height: 72px;
        }
    }
}