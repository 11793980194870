@keyframes modalWindow {
    0% { transform: scale(0.75); }
    100% { transform: scale(1); }
}

.c-modal {
    --color: 0, 204, 204;
    --color-bg: 255, 255, 255;
    --color-input-border: 255, 255, 255;
    --color-input-border-focus: 255, 255, 255;
    --color-link: 0, 204, 204;
    --color-link-hover: 0, 0, 0;
    --color-text: 0, 0, 0;
    --equinox: 0;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    bottom: 0;
    display: none;
    justify-content: center;
    left: 0;
    padding: 8px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
    
    &__window {
        animation: modalWindow 250ms both cubic-bezier(.25, 1, .25, 1);
        background: rgb(var(--color-1));
        border-radius: var(--border-radius);
        box-shadow: 0 24px 48px rgba(0, 0, 0, 0.25);
        color: rgb(var(--color-text));
        max-height: calc(100vh - 16px);
        max-width: 800px;
        overflow: hidden;
        position: relative;
        width: 100%;
        will-change: transform;

        @include screen-min(m) {
            display: flex;
            align-items: stretch;
        }
    }

    &__image {
        background-position: center;
        background-size: cover;
        height: 50vw;

        @include screen-min(m) {
            height: auto;
            flex: 0 0 50%;
        }
    }

    &__main {
        flex: 0 0 50%;

        @include screen-min(m) {
            position: relative;
        }
    }

    &__hide {
        background: #000;
        border: none;
        color: transparent;
        cursor: pointer;
        height: 48px;
        mask-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><line x1="18" y1="18" x2="30" y2="30" fill="none" stroke="%23000" stroke-miterlimit="10" stroke-width="1.5"/><line x1="30" y1="18" x2="18" y2="30" fill="none" stroke="%23000" stroke-miterlimit="10" stroke-width="1.5"/></svg>');
        mask-size: 100%;
        opacity: 0.75;
        position: absolute;
        top: 0;
        right: 0;
        width: 48px;

        @include screen-min(m) {
            opacity: 0.5;
        }

        &:hover {
            opacity: 1;
        }
    }

    &__main {
        padding: space(xl);

        @include screen-max(m) {
            font-size: var(--font-size-s);
        }
    }

    &.is-visible {
        display: flex;
    }
}

.has-modal {
    overflow: hidden;
}