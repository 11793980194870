.b-media {
    --equinox: 0;

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__body {
        margin-bottom: space(xl);
    }

    &__player {
        border-radius: 4px;;
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
        overflow: hidden;

        @include screen-min(l) {
            transform: translate3d(0, calc(var(--equinox) * 15%), 0);
            will-change: transform;
        }
    }
}