.c-button {
    @include font-1;
    appearance: none;
    background-color: rgb(var(--color-button));
    border-radius: 24px;
    border: none;
    cursor: pointer;
    color: rgb(var(--color-button-text));
    display: inline-block;
    font-size: var(--font-size-m);
    font-weight: 600;
    height: 48px;
    line-height: 20px;
    padding: 14px 24px;
    text-align: center;
    text-decoration: none;
    transition: background-color var(--duration-slow) cubic-bezier(.25, 1, .25, 1);
    user-select: none;
    white-space: nowrap;

    @include screen-min(l) {
        border-radius: 28px;
        height: 56px;
        padding: 18px 40px;
    }

    &:focus {
    }

    &:hover {
        background: rgb(var(--color-button-hover));
        color: rgb(var(--color-bg));
        text-decoration: none;
    }

    &__icon {
        display: block;
        fill: rgb(var(--color-bg));
        float: left;
        margin-right: 4px;
        position: relative;
    }

    &--inverted {
        background: rgb(var(--color-bg));
        box-shadow: none;
        color: rgb(var(--color-3));
        font-weight: 600;

        &:hover {
            box-shadow: none;
        }
    }
    
    &--primary {
        background: rgb(var(--color-3));
        color: rgb(var(--color-bg));
        font-weight: 600;
    }

    &--large {
        @include screen-min(l) {
            border-radius: 32px;
            font-size: var(--font-size-l);
            height: 64px;
            line-height: 24px;
            padding: 20px 40px;
    
            .c-button__icon {
                height: 24px;
                margin-right: 12px;
                width: 24px;
            }
        }
    }
}