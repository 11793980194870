.b-clients {
    margin: 0 24px;
    text-align: center;

    &__heading {
        margin-bottom: space(xxl);
        text-align: center;
    }

    &__list {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: space(xl);
        justify-content: center;
        margin-bottom: space(xxl);
    }

    &__logo {
        height: 48px;

        @include screen-min(l) {
            height: 72px;
        }
    }

    &__quote {
        margin: 0 auto;
        max-width: 75%;

        &::before {
            background: rgb(var(--color-1));
            content: '';
            display: block;
            height: 3px;
            margin: 0 auto 32px auto;
            width: 80px;
        }
    }

    &__quoteText {
        font-size: var(--font-size-l);
        font-weight: 600;
        margin-bottom: 16px;

        @include screen-min(l) {
            font-size: var(--font-size-xl);
        }
    }

    &__quoteAuthor,
    &__quoteAuthorCompany {
        color: rgb(var(--color-1));
        font-size: var(--font-size-s);

        @include screen-min(l) {
            font-size: var(--font-size-m);
        }
    }

    &__quoteAuthor {
        font-weight: 600;
    }
}