@keyframes geekoutSuccess {
    0% { opacity: 0; transform: scale(0.5); }
    100% { opacity: 1; transform: scale(1); }
}

.b-geekout {
    --color: 255, 166, 66;
    --color-bg: 255, 255, 255;
    --color-input-border: 204, 204, 204;
    --color-input-border-focus: 102, 102, 102;
    --color-link: 255, 166, 66;
    --color-link-hover: 0, 0, 0;
    --equinox: 0;
    background: #FFF;
    color: #000;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    overflow: hidden;
    position: relative;

    @include screen-max(l) {
        padding-top: 0 !important;
    }

    &__layers {
        height: 65vw;
        overflow: hidden;
        position: relative;

        @include screen-min(m) {
            height: 60vw;
        }

        @include screen-min(l) {
            height: auto;
            overflow: visible;
            position: static;
        }

        &:after {
            background-image: url('/assets/img/geekout-fade.png');
            background-repeat: repeat-x;
            background-size: 100% auto;
            bottom: 0;
            content: '';
            height: 80px;
            left: 0;
            position: absolute;
            width: 100%;
            z-index: 3;

            @include screen-min(m) {
                background-image: url('/assets/img/geekout-fade-m.png');
                background-repeat: repeat-x;
                background-size: 100% 100%;
                height: 40px;
            }

            @include screen-min(l) {
                display: none;
            }
        }
    }

    &__bg,
    &__fg {
        background-position: top right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        will-change: transform;

        @include screen-min(l) {
            height: 100%;
        }
    }

    &__bg {
        background-image: url('/assets/img/geekout-bg.jpg');
        height: 110%;
        transform: translate3d(0, calc(var(--equinox-pos) * -11.11111%), 0);
        z-index: 1;
    }

    &__fg {
        background-image: url('/assets/img/geekout-fg-tiny.png');
        height: 125%;
        transform: translate3d(0, calc(var(--equinox-pos) * -20%), 0);
        z-index: 2;
    }

    .o-wrapper {
        align-items: flex-start;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        z-index: 2;
    }

    &__logo {
        height: 52px;
        position: absolute;
        top: 100px;

        @include screen-min(m) {
            height: 72px;
            top: 96px;
        }

        @include screen-min(l) {
            height: 88px;
            position: static;
            top: 0;
        }
    }

    &__content {
        padding-top: space(l);
        position: relative;
        z-index: 4;

        @include screen-min(m) {
            padding-top: 0;
        }

        @include screen-min(l) {
            padding-top: 0;
            width: 50%;
        }
    }

    &__heading {
        font-family: Caveat, sans-serif;
        font-size: var(--font-size-3xl);
        letter-spacing: -0.01em;
        line-height: 1;
        margin-bottom: space(xl);
        
        @include screen-min(m) {
            font-size: var(--font-size-xxl);
            margin: -160px 0 80px 0;
            width: 50%;
        }

        @include screen-min(l) {
            margin: 0 0 space(xl) 0;
            width: 100%;
        }
    }

    &__intro {
        margin-bottom: space(xxl);

        @include screen-min(l) {
            font-size: var(--font-size-l);
        }
    }

    &__ctaHeading {
        text-align: center;

        @include screen-min(l) {
            text-align: left;
        }
    }

    &__subscribe {
        .is-success & {
            display: none;
        }
    }

    &__success {
        animation: geekoutSuccess var(--duration-medium) cubic-bezier(0, 1, 0.75, 1.5) both;
        background: rgb(var(--color));
        border-radius: var(--border-radius);
        color: #FFF;
        display: none;
        font-weight: 600;
        line-height: 56px;
        padding: 0 14px;
        text-align: center;
        will-change: opacity, transform;

        .is-success & {
            display: block;
        }
    }
}