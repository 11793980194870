:root {
    --border-radius: 4px;
    --duration-fast: 150ms;
    --duration-medium: 250ms;
    --duration-slow: 500ms;

    // Color
    --color-1: 0, 221, 221;             // #00DDDD

    --color-bg: 0, 0, 0;                // #FFFFFF
    --color-bg-tint: 0, 28, 28;         // #002929

    --color-text: 255, 255, 255;        // #FFFFFF
    --color-text-muted: 127, 138, 167;  // #7F8AA7

    --color-status-bad: 255, 51 51;     // #FF3333
    --color-status-good: 51, 204, 102;  // #33CC66

    --color-border: 227, 227, 227;      // #E3E3E3

    --color-input-border: 0, 0, 0;
    --color-input-border-focus: var(--color-1);
    --color-input-outline: rgba(var(--color-1-rgb), 0.125);
    --color-input-placeholder: #BBBDC1; // 30% text

    --color-button: 0, 221, 221;        // #00DDDD
    --color-button-hover: 0, 255, 255;  // #00EEEE
    --color-button-text: 0, 0, 0;       // #000000

    --color-link: 0, 221, 221;          // #00DDDD
    --color-link-hover: 0, 238, 238;    // #00EEEE

    // Type
    --font-1: 'Metropolis', sans-serif;
    --font-1-spacing: 0;

    --font-2: 'Metropolis', sans-serif;
    --font-2-spacing: 0;

    --font-mono: monospace;
    --font-mono-spacing: 0;

    --font-size-xxs: 11px;
    --font-size-xs: 12px;
    --font-size-s: 14px;
    --font-size-m: 16px;
    --font-size-l: 18px;
    --font-size-xl: 20px;
    --font-size-xxl: 28px;
    --font-size-3xl: 32px;

    --line-height-s: 1.3;
    --line-height-m: 1.5;
    --line-height-l: 1.75;
}

@media (min-width: 768px) {
    :root {
        --font-size-xxl: 36px;
    }
}

@media (min-width: 1024px) {
    :root {
        --font-size-l: 20px;
        --font-size-xl: 24px;
        --font-size-xxl: 48px;
    }
}

$grid-columns: 12 !default;

$screens: (
    xs: 320px,
     s: 375px,
     m: 768px,
     l: 1024px,
    xl: 1280px,
) !default;

$spaces: (
    xxs: 0.125rem, // 2px
     xs: 0.25rem,  // 4px
      s: 0.5rem,   // 8px
      m: 1rem,     // 16px
      l: 1.5rem,   // 24px
     xl: 2rem,     // 32px
    xxl: 3rem,     // 48px
    3xl: 4rem,     // 64px
    4xl: 5rem,     // 80px
    5xl: 6rem,     // 96px
    6xl: 7rem,     // 112px
) !default;

$wrapper-widths: (
    s: 640px,
    m: 800px,
    l: 1440px,
) !default;