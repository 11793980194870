/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/Metropolis-Light.woff2") format("woff2"), url("/assets/fonts/Metropolis-Light.woff") format("woff");
}
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Metropolis-Regular.woff2") format("woff2"), url("/assets/fonts/Metropolis-Regular.woff") format("woff");
}
@font-face {
  font-family: "Metropolis";
  font-style: italic;
  font-weight: 400;
  src: url("/assets/fonts/Metropolis-RegularItalic.woff2") format("woff2"), url("/assets/fonts/Metropolis-RegularItalic.woff") format("woff");
}
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/Metropolis-SemiBold.woff2") format("woff2"), url("/assets/fonts/Metropolis-SemiBold.woff") format("woff");
}
:root {
  --border-radius: 4px;
  --duration-fast: 150ms;
  --duration-medium: 250ms;
  --duration-slow: 500ms;
  --color-1: 0, 221, 221;
  --color-bg: 0, 0, 0;
  --color-bg-tint: 0, 28, 28;
  --color-text: 255, 255, 255;
  --color-text-muted: 127, 138, 167;
  --color-status-bad: 255, 51 51;
  --color-status-good: 51, 204, 102;
  --color-border: 227, 227, 227;
  --color-input-border: 0, 0, 0;
  --color-input-border-focus: var(--color-1);
  --color-input-outline: rgba(var(--color-1-rgb), 0.125);
  --color-input-placeholder: #BBBDC1;
  --color-button: 0, 221, 221;
  --color-button-hover: 0, 255, 255;
  --color-button-text: 0, 0, 0;
  --color-link: 0, 221, 221;
  --color-link-hover: 0, 238, 238;
  --font-1: "Metropolis", sans-serif;
  --font-1-spacing: 0;
  --font-2: "Metropolis", sans-serif;
  --font-2-spacing: 0;
  --font-mono: monospace;
  --font-mono-spacing: 0;
  --font-size-xxs: 11px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 18px;
  --font-size-xl: 20px;
  --font-size-xxl: 28px;
  --font-size-3xl: 32px;
  --line-height-s: 1.3;
  --line-height-m: 1.5;
  --line-height-l: 1.75;
}

@media (min-width: 768px) {
  :root {
    --font-size-xxl: 36px;
  }
}
@media (min-width: 1024px) {
  :root {
    --font-size-l: 20px;
    --font-size-xl: 24px;
    --font-size-xxl: 48px;
  }
}
@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}
a {
  color: rgb(var(--color-link));
  text-decoration: underline;
}
a:hover {
  color: rgb(var(--color-link-hover));
}

b,
strong {
  font-weight: 600;
}

body {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  background: rgb(var(--color-bg));
  color: rgb(var(--color-text));
  line-height: var(--line-height-m);
  padding-bottom: 256px;
  text-rendering: optimizelegibility;
}
@media (min-width: 768px) {
  body {
    padding-bottom: 320px;
  }
}
body.is-loading, body.is-loading * {
  cursor: wait;
  pointer-events: none;
}

button {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  display: inline-block;
  margin: 0;
  padding: 0;
}

form.is-busy, form.is-busy * {
  cursor: wait;
  pointer-events: none;
}

*,
*::after,
*::before {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  box-sizing: border-box;
  outline: none;
}

h1 {
  font-size: var(--font-size-xxl);
  font-weight: 300;
  line-height: var(--line-height-s);
}

h2 {
  font-family: var(--font-2);
  letter-spacing: var(--font-2-spacing);
  color: rgb(var(--color-1));
  font-size: var(--font-size-xxl);
  font-weight: 300;
  line-height: var(--line-height-s);
}

h3 {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  color: rgb(var(--color-1));
  font-size: var(--font-size-l);
  font-weight: 600;
  line-height: var(--line-height-s);
}

h4 {
  font-size: var(--font-size-m);
  font-weight: 700;
  line-height: line-height(s);
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

input {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  margin: 0;
  outline: none;
  padding: 0;
}

input::placeholder {
  color: var(--color-input-placeholder);
  font-family: var(--font-1);
  opacity: 1;
  text-transform: none;
}

input::-ms-input-placeholder {
  color: var(--color-input-placeholder);
  font-family: var(--font-1);
  opacity: 1;
  text-transform: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background 9999s;
  transition-delay: 9999s;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.o-grid {
  display: flex;
  flex-flow: row wrap;
}
.o-grid__col {
  background-clip: padding-box;
  border: 0px solid transparent;
  flex: 0 0 100%;
}
.o-grid--center {
  justify-content: center;
}
.o-grid--middle {
  align-items: center;
}
.o-grid--evenly {
  justify-content: space-evenly;
}

.o-grid__col--span-1 {
  flex-basis: calc(1 * (100% / 12));
}

.o-grid__col--span-2 {
  flex-basis: calc(2 * (100% / 12));
}

.o-grid__col--span-3 {
  flex-basis: calc(3 * (100% / 12));
}

.o-grid__col--span-4 {
  flex-basis: calc(4 * (100% / 12));
}

.o-grid__col--span-5 {
  flex-basis: calc(5 * (100% / 12));
}

.o-grid__col--span-6 {
  flex-basis: calc(6 * (100% / 12));
}

.o-grid__col--span-7 {
  flex-basis: calc(7 * (100% / 12));
}

.o-grid__col--span-8 {
  flex-basis: calc(8 * (100% / 12));
}

.o-grid__col--span-9 {
  flex-basis: calc(9 * (100% / 12));
}

.o-grid__col--span-10 {
  flex-basis: calc(10 * (100% / 12));
}

.o-grid__col--span-11 {
  flex-basis: calc(11 * (100% / 12));
}

.o-grid__col--span-12 {
  flex-basis: calc(12 * (100% / 12));
}

.o-grid--gap-xxs {
  margin: -0.0625rem;
}
.o-grid--gap-xxs > .o-grid__col {
  border-width: 0.0625rem;
}

.o-grid--gap-xs {
  margin: -0.125rem;
}
.o-grid--gap-xs > .o-grid__col {
  border-width: 0.125rem;
}

.o-grid--gap-s {
  margin: -0.25rem;
}
.o-grid--gap-s > .o-grid__col {
  border-width: 0.25rem;
}

.o-grid--gap-m {
  margin: -0.5rem;
}
.o-grid--gap-m > .o-grid__col {
  border-width: 0.5rem;
}

.o-grid--gap-l {
  margin: -0.75rem;
}
.o-grid--gap-l > .o-grid__col {
  border-width: 0.75rem;
}

.o-grid--gap-xl {
  margin: -1rem;
}
.o-grid--gap-xl > .o-grid__col {
  border-width: 1rem;
}

.o-grid--gap-xxl {
  margin: -1.5rem;
}
.o-grid--gap-xxl > .o-grid__col {
  border-width: 1.5rem;
}

.o-grid--gap-3xl {
  margin: -2rem;
}
.o-grid--gap-3xl > .o-grid__col {
  border-width: 2rem;
}

.o-grid--gap-4xl {
  margin: -2.5rem;
}
.o-grid--gap-4xl > .o-grid__col {
  border-width: 2.5rem;
}

.o-grid--gap-5xl {
  margin: -3rem;
}
.o-grid--gap-5xl > .o-grid__col {
  border-width: 3rem;
}

.o-grid--gap-6xl {
  margin: -3.5rem;
}
.o-grid--gap-6xl > .o-grid__col {
  border-width: 3.5rem;
}

@media (min-width: 320px) {
  .xs\:o-grid--reverse {
    flex-direction: row-reverse;
  }

  .xs\:o-grid__col--span-1 {
    flex-basis: calc(1 * (100% / 12));
  }

  .xs\:o-grid__col--span-2 {
    flex-basis: calc(2 * (100% / 12));
  }

  .xs\:o-grid__col--span-3 {
    flex-basis: calc(3 * (100% / 12));
  }

  .xs\:o-grid__col--span-4 {
    flex-basis: calc(4 * (100% / 12));
  }

  .xs\:o-grid__col--span-5 {
    flex-basis: calc(5 * (100% / 12));
  }

  .xs\:o-grid__col--span-6 {
    flex-basis: calc(6 * (100% / 12));
  }

  .xs\:o-grid__col--span-7 {
    flex-basis: calc(7 * (100% / 12));
  }

  .xs\:o-grid__col--span-8 {
    flex-basis: calc(8 * (100% / 12));
  }

  .xs\:o-grid__col--span-9 {
    flex-basis: calc(9 * (100% / 12));
  }

  .xs\:o-grid__col--span-10 {
    flex-basis: calc(10 * (100% / 12));
  }

  .xs\:o-grid__col--span-11 {
    flex-basis: calc(11 * (100% / 12));
  }

  .xs\:o-grid__col--span-12 {
    flex-basis: calc(12 * (100% / 12));
  }

  .xs\:o-grid--gap-xxs {
    margin: -0.0625rem;
  }
  .xs\:o-grid--gap-xxs > .o-grid__col {
    border-width: 0.0625rem;
  }

  .xs\:o-grid--gap-xs {
    margin: -0.125rem;
  }
  .xs\:o-grid--gap-xs > .o-grid__col {
    border-width: 0.125rem;
  }

  .xs\:o-grid--gap-s {
    margin: -0.25rem;
  }
  .xs\:o-grid--gap-s > .o-grid__col {
    border-width: 0.25rem;
  }

  .xs\:o-grid--gap-m {
    margin: -0.5rem;
  }
  .xs\:o-grid--gap-m > .o-grid__col {
    border-width: 0.5rem;
  }

  .xs\:o-grid--gap-l {
    margin: -0.75rem;
  }
  .xs\:o-grid--gap-l > .o-grid__col {
    border-width: 0.75rem;
  }

  .xs\:o-grid--gap-xl {
    margin: -1rem;
  }
  .xs\:o-grid--gap-xl > .o-grid__col {
    border-width: 1rem;
  }

  .xs\:o-grid--gap-xxl {
    margin: -1.5rem;
  }
  .xs\:o-grid--gap-xxl > .o-grid__col {
    border-width: 1.5rem;
  }

  .xs\:o-grid--gap-3xl {
    margin: -2rem;
  }
  .xs\:o-grid--gap-3xl > .o-grid__col {
    border-width: 2rem;
  }

  .xs\:o-grid--gap-4xl {
    margin: -2.5rem;
  }
  .xs\:o-grid--gap-4xl > .o-grid__col {
    border-width: 2.5rem;
  }

  .xs\:o-grid--gap-5xl {
    margin: -3rem;
  }
  .xs\:o-grid--gap-5xl > .o-grid__col {
    border-width: 3rem;
  }

  .xs\:o-grid--gap-6xl {
    margin: -3.5rem;
  }
  .xs\:o-grid--gap-6xl > .o-grid__col {
    border-width: 3.5rem;
  }
}
@media (min-width: 375px) {
  .s\:o-grid--reverse {
    flex-direction: row-reverse;
  }

  .s\:o-grid__col--span-1 {
    flex-basis: calc(1 * (100% / 12));
  }

  .s\:o-grid__col--span-2 {
    flex-basis: calc(2 * (100% / 12));
  }

  .s\:o-grid__col--span-3 {
    flex-basis: calc(3 * (100% / 12));
  }

  .s\:o-grid__col--span-4 {
    flex-basis: calc(4 * (100% / 12));
  }

  .s\:o-grid__col--span-5 {
    flex-basis: calc(5 * (100% / 12));
  }

  .s\:o-grid__col--span-6 {
    flex-basis: calc(6 * (100% / 12));
  }

  .s\:o-grid__col--span-7 {
    flex-basis: calc(7 * (100% / 12));
  }

  .s\:o-grid__col--span-8 {
    flex-basis: calc(8 * (100% / 12));
  }

  .s\:o-grid__col--span-9 {
    flex-basis: calc(9 * (100% / 12));
  }

  .s\:o-grid__col--span-10 {
    flex-basis: calc(10 * (100% / 12));
  }

  .s\:o-grid__col--span-11 {
    flex-basis: calc(11 * (100% / 12));
  }

  .s\:o-grid__col--span-12 {
    flex-basis: calc(12 * (100% / 12));
  }

  .s\:o-grid--gap-xxs {
    margin: -0.0625rem;
  }
  .s\:o-grid--gap-xxs > .o-grid__col {
    border-width: 0.0625rem;
  }

  .s\:o-grid--gap-xs {
    margin: -0.125rem;
  }
  .s\:o-grid--gap-xs > .o-grid__col {
    border-width: 0.125rem;
  }

  .s\:o-grid--gap-s {
    margin: -0.25rem;
  }
  .s\:o-grid--gap-s > .o-grid__col {
    border-width: 0.25rem;
  }

  .s\:o-grid--gap-m {
    margin: -0.5rem;
  }
  .s\:o-grid--gap-m > .o-grid__col {
    border-width: 0.5rem;
  }

  .s\:o-grid--gap-l {
    margin: -0.75rem;
  }
  .s\:o-grid--gap-l > .o-grid__col {
    border-width: 0.75rem;
  }

  .s\:o-grid--gap-xl {
    margin: -1rem;
  }
  .s\:o-grid--gap-xl > .o-grid__col {
    border-width: 1rem;
  }

  .s\:o-grid--gap-xxl {
    margin: -1.5rem;
  }
  .s\:o-grid--gap-xxl > .o-grid__col {
    border-width: 1.5rem;
  }

  .s\:o-grid--gap-3xl {
    margin: -2rem;
  }
  .s\:o-grid--gap-3xl > .o-grid__col {
    border-width: 2rem;
  }

  .s\:o-grid--gap-4xl {
    margin: -2.5rem;
  }
  .s\:o-grid--gap-4xl > .o-grid__col {
    border-width: 2.5rem;
  }

  .s\:o-grid--gap-5xl {
    margin: -3rem;
  }
  .s\:o-grid--gap-5xl > .o-grid__col {
    border-width: 3rem;
  }

  .s\:o-grid--gap-6xl {
    margin: -3.5rem;
  }
  .s\:o-grid--gap-6xl > .o-grid__col {
    border-width: 3.5rem;
  }
}
@media (min-width: 768px) {
  .m\:o-grid--reverse {
    flex-direction: row-reverse;
  }

  .m\:o-grid__col--span-1 {
    flex-basis: calc(1 * (100% / 12));
  }

  .m\:o-grid__col--span-2 {
    flex-basis: calc(2 * (100% / 12));
  }

  .m\:o-grid__col--span-3 {
    flex-basis: calc(3 * (100% / 12));
  }

  .m\:o-grid__col--span-4 {
    flex-basis: calc(4 * (100% / 12));
  }

  .m\:o-grid__col--span-5 {
    flex-basis: calc(5 * (100% / 12));
  }

  .m\:o-grid__col--span-6 {
    flex-basis: calc(6 * (100% / 12));
  }

  .m\:o-grid__col--span-7 {
    flex-basis: calc(7 * (100% / 12));
  }

  .m\:o-grid__col--span-8 {
    flex-basis: calc(8 * (100% / 12));
  }

  .m\:o-grid__col--span-9 {
    flex-basis: calc(9 * (100% / 12));
  }

  .m\:o-grid__col--span-10 {
    flex-basis: calc(10 * (100% / 12));
  }

  .m\:o-grid__col--span-11 {
    flex-basis: calc(11 * (100% / 12));
  }

  .m\:o-grid__col--span-12 {
    flex-basis: calc(12 * (100% / 12));
  }

  .m\:o-grid--gap-xxs {
    margin: -0.0625rem;
  }
  .m\:o-grid--gap-xxs > .o-grid__col {
    border-width: 0.0625rem;
  }

  .m\:o-grid--gap-xs {
    margin: -0.125rem;
  }
  .m\:o-grid--gap-xs > .o-grid__col {
    border-width: 0.125rem;
  }

  .m\:o-grid--gap-s {
    margin: -0.25rem;
  }
  .m\:o-grid--gap-s > .o-grid__col {
    border-width: 0.25rem;
  }

  .m\:o-grid--gap-m {
    margin: -0.5rem;
  }
  .m\:o-grid--gap-m > .o-grid__col {
    border-width: 0.5rem;
  }

  .m\:o-grid--gap-l {
    margin: -0.75rem;
  }
  .m\:o-grid--gap-l > .o-grid__col {
    border-width: 0.75rem;
  }

  .m\:o-grid--gap-xl {
    margin: -1rem;
  }
  .m\:o-grid--gap-xl > .o-grid__col {
    border-width: 1rem;
  }

  .m\:o-grid--gap-xxl {
    margin: -1.5rem;
  }
  .m\:o-grid--gap-xxl > .o-grid__col {
    border-width: 1.5rem;
  }

  .m\:o-grid--gap-3xl {
    margin: -2rem;
  }
  .m\:o-grid--gap-3xl > .o-grid__col {
    border-width: 2rem;
  }

  .m\:o-grid--gap-4xl {
    margin: -2.5rem;
  }
  .m\:o-grid--gap-4xl > .o-grid__col {
    border-width: 2.5rem;
  }

  .m\:o-grid--gap-5xl {
    margin: -3rem;
  }
  .m\:o-grid--gap-5xl > .o-grid__col {
    border-width: 3rem;
  }

  .m\:o-grid--gap-6xl {
    margin: -3.5rem;
  }
  .m\:o-grid--gap-6xl > .o-grid__col {
    border-width: 3.5rem;
  }
}
@media (min-width: 1024px) {
  .l\:o-grid--reverse {
    flex-direction: row-reverse;
  }

  .l\:o-grid__col--span-1 {
    flex-basis: calc(1 * (100% / 12));
  }

  .l\:o-grid__col--span-2 {
    flex-basis: calc(2 * (100% / 12));
  }

  .l\:o-grid__col--span-3 {
    flex-basis: calc(3 * (100% / 12));
  }

  .l\:o-grid__col--span-4 {
    flex-basis: calc(4 * (100% / 12));
  }

  .l\:o-grid__col--span-5 {
    flex-basis: calc(5 * (100% / 12));
  }

  .l\:o-grid__col--span-6 {
    flex-basis: calc(6 * (100% / 12));
  }

  .l\:o-grid__col--span-7 {
    flex-basis: calc(7 * (100% / 12));
  }

  .l\:o-grid__col--span-8 {
    flex-basis: calc(8 * (100% / 12));
  }

  .l\:o-grid__col--span-9 {
    flex-basis: calc(9 * (100% / 12));
  }

  .l\:o-grid__col--span-10 {
    flex-basis: calc(10 * (100% / 12));
  }

  .l\:o-grid__col--span-11 {
    flex-basis: calc(11 * (100% / 12));
  }

  .l\:o-grid__col--span-12 {
    flex-basis: calc(12 * (100% / 12));
  }

  .l\:o-grid--gap-xxs {
    margin: -0.0625rem;
  }
  .l\:o-grid--gap-xxs > .o-grid__col {
    border-width: 0.0625rem;
  }

  .l\:o-grid--gap-xs {
    margin: -0.125rem;
  }
  .l\:o-grid--gap-xs > .o-grid__col {
    border-width: 0.125rem;
  }

  .l\:o-grid--gap-s {
    margin: -0.25rem;
  }
  .l\:o-grid--gap-s > .o-grid__col {
    border-width: 0.25rem;
  }

  .l\:o-grid--gap-m {
    margin: -0.5rem;
  }
  .l\:o-grid--gap-m > .o-grid__col {
    border-width: 0.5rem;
  }

  .l\:o-grid--gap-l {
    margin: -0.75rem;
  }
  .l\:o-grid--gap-l > .o-grid__col {
    border-width: 0.75rem;
  }

  .l\:o-grid--gap-xl {
    margin: -1rem;
  }
  .l\:o-grid--gap-xl > .o-grid__col {
    border-width: 1rem;
  }

  .l\:o-grid--gap-xxl {
    margin: -1.5rem;
  }
  .l\:o-grid--gap-xxl > .o-grid__col {
    border-width: 1.5rem;
  }

  .l\:o-grid--gap-3xl {
    margin: -2rem;
  }
  .l\:o-grid--gap-3xl > .o-grid__col {
    border-width: 2rem;
  }

  .l\:o-grid--gap-4xl {
    margin: -2.5rem;
  }
  .l\:o-grid--gap-4xl > .o-grid__col {
    border-width: 2.5rem;
  }

  .l\:o-grid--gap-5xl {
    margin: -3rem;
  }
  .l\:o-grid--gap-5xl > .o-grid__col {
    border-width: 3rem;
  }

  .l\:o-grid--gap-6xl {
    margin: -3.5rem;
  }
  .l\:o-grid--gap-6xl > .o-grid__col {
    border-width: 3.5rem;
  }
}
@media (min-width: 1280px) {
  .xl\:o-grid--reverse {
    flex-direction: row-reverse;
  }

  .xl\:o-grid__col--span-1 {
    flex-basis: calc(1 * (100% / 12));
  }

  .xl\:o-grid__col--span-2 {
    flex-basis: calc(2 * (100% / 12));
  }

  .xl\:o-grid__col--span-3 {
    flex-basis: calc(3 * (100% / 12));
  }

  .xl\:o-grid__col--span-4 {
    flex-basis: calc(4 * (100% / 12));
  }

  .xl\:o-grid__col--span-5 {
    flex-basis: calc(5 * (100% / 12));
  }

  .xl\:o-grid__col--span-6 {
    flex-basis: calc(6 * (100% / 12));
  }

  .xl\:o-grid__col--span-7 {
    flex-basis: calc(7 * (100% / 12));
  }

  .xl\:o-grid__col--span-8 {
    flex-basis: calc(8 * (100% / 12));
  }

  .xl\:o-grid__col--span-9 {
    flex-basis: calc(9 * (100% / 12));
  }

  .xl\:o-grid__col--span-10 {
    flex-basis: calc(10 * (100% / 12));
  }

  .xl\:o-grid__col--span-11 {
    flex-basis: calc(11 * (100% / 12));
  }

  .xl\:o-grid__col--span-12 {
    flex-basis: calc(12 * (100% / 12));
  }

  .xl\:o-grid--gap-xxs {
    margin: -0.0625rem;
  }
  .xl\:o-grid--gap-xxs > .o-grid__col {
    border-width: 0.0625rem;
  }

  .xl\:o-grid--gap-xs {
    margin: -0.125rem;
  }
  .xl\:o-grid--gap-xs > .o-grid__col {
    border-width: 0.125rem;
  }

  .xl\:o-grid--gap-s {
    margin: -0.25rem;
  }
  .xl\:o-grid--gap-s > .o-grid__col {
    border-width: 0.25rem;
  }

  .xl\:o-grid--gap-m {
    margin: -0.5rem;
  }
  .xl\:o-grid--gap-m > .o-grid__col {
    border-width: 0.5rem;
  }

  .xl\:o-grid--gap-l {
    margin: -0.75rem;
  }
  .xl\:o-grid--gap-l > .o-grid__col {
    border-width: 0.75rem;
  }

  .xl\:o-grid--gap-xl {
    margin: -1rem;
  }
  .xl\:o-grid--gap-xl > .o-grid__col {
    border-width: 1rem;
  }

  .xl\:o-grid--gap-xxl {
    margin: -1.5rem;
  }
  .xl\:o-grid--gap-xxl > .o-grid__col {
    border-width: 1.5rem;
  }

  .xl\:o-grid--gap-3xl {
    margin: -2rem;
  }
  .xl\:o-grid--gap-3xl > .o-grid__col {
    border-width: 2rem;
  }

  .xl\:o-grid--gap-4xl {
    margin: -2.5rem;
  }
  .xl\:o-grid--gap-4xl > .o-grid__col {
    border-width: 2.5rem;
  }

  .xl\:o-grid--gap-5xl {
    margin: -3rem;
  }
  .xl\:o-grid--gap-5xl > .o-grid__col {
    border-width: 3rem;
  }

  .xl\:o-grid--gap-6xl {
    margin: -3.5rem;
  }
  .xl\:o-grid--gap-6xl > .o-grid__col {
    border-width: 3.5rem;
  }
}
.o-media {
  width: 100%;
  height: 0;
  position: relative;
}
.o-media iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.o-media--1\:1 {
  padding-top: 100%;
}
.o-media--16\:9 {
  padding-top: 56.25%;
}

.o-wrapper {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1440px;
  width: 100%;
}
@media (min-width: 768px) {
  .o-wrapper {
    padding-left: 48px;
    padding-right: 48px;
  }
}
.o-wrapper__bleed {
  margin-left: -24px;
  margin-right: -24px;
}
@media (min-width: 768px) {
  .o-wrapper__bleed {
    margin-left: -48px;
    margin-right: -48px;
  }
}
@media (min-width: 1024px) {
  .o-wrapper__bleed {
    margin-left: 0;
    margin-right: 0;
  }
}
.o-wrapper--narrow {
  max-width: 800px;
}

.b-clients {
  margin: 0 24px;
  text-align: center;
}
.b-clients__heading {
  margin-bottom: 3rem;
  text-align: center;
}
.b-clients__list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  margin-bottom: 3rem;
}
.b-clients__logo {
  height: 48px;
}
@media (min-width: 1024px) {
  .b-clients__logo {
    height: 72px;
  }
}
.b-clients__quote {
  margin: 0 auto;
  max-width: 75%;
}
.b-clients__quote::before {
  background: rgb(var(--color-1));
  content: "";
  display: block;
  height: 3px;
  margin: 0 auto 32px auto;
  width: 80px;
}
.b-clients__quoteText {
  font-size: var(--font-size-l);
  font-weight: 600;
  margin-bottom: 16px;
}
@media (min-width: 1024px) {
  .b-clients__quoteText {
    font-size: var(--font-size-xl);
  }
}
.b-clients__quoteAuthor, .b-clients__quoteAuthorCompany {
  color: rgb(var(--color-1));
  font-size: var(--font-size-s);
}
@media (min-width: 1024px) {
  .b-clients__quoteAuthor, .b-clients__quoteAuthorCompany {
    font-size: var(--font-size-m);
  }
}
.b-clients__quoteAuthor {
  font-weight: 600;
}

@keyframes geekoutSuccess {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.b-geekout {
  --color: 255, 166, 66;
  --color-bg: 255, 255, 255;
  --color-input-border: 204, 204, 204;
  --color-input-border-focus: 102, 102, 102;
  --color-link: 255, 166, 66;
  --color-link-hover: 0, 0, 0;
  --equinox: 0;
  background: #FFF;
  color: #000;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1023px) {
  .b-geekout {
    padding-top: 0 !important;
  }
}
.b-geekout__layers {
  height: 65vw;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  .b-geekout__layers {
    height: 60vw;
  }
}
@media (min-width: 1024px) {
  .b-geekout__layers {
    height: auto;
    overflow: visible;
    position: static;
  }
}
.b-geekout__layers:after {
  background-image: url("/assets/img/geekout-fade.png");
  background-repeat: repeat-x;
  background-size: 100% auto;
  bottom: 0;
  content: "";
  height: 80px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 3;
}
@media (min-width: 768px) {
  .b-geekout__layers:after {
    background-image: url("/assets/img/geekout-fade-m.png");
    background-repeat: repeat-x;
    background-size: 100% 100%;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  .b-geekout__layers:after {
    display: none;
  }
}
.b-geekout__bg, .b-geekout__fg {
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
}
@media (min-width: 1024px) {
  .b-geekout__bg, .b-geekout__fg {
    height: 100%;
  }
}
.b-geekout__bg {
  background-image: url("/assets/img/geekout-bg.jpg");
  height: 110%;
  transform: translate3d(0, calc(var(--equinox-pos) * -11.11111%), 0);
  z-index: 1;
}
.b-geekout__fg {
  background-image: url("/assets/img/geekout-fg-tiny.png");
  height: 125%;
  transform: translate3d(0, calc(var(--equinox-pos) * -20%), 0);
  z-index: 2;
}
.b-geekout .o-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
}
.b-geekout__logo {
  height: 52px;
  position: absolute;
  top: 100px;
}
@media (min-width: 768px) {
  .b-geekout__logo {
    height: 72px;
    top: 96px;
  }
}
@media (min-width: 1024px) {
  .b-geekout__logo {
    height: 88px;
    position: static;
    top: 0;
  }
}
.b-geekout__content {
  padding-top: 1.5rem;
  position: relative;
  z-index: 4;
}
@media (min-width: 768px) {
  .b-geekout__content {
    padding-top: 0;
  }
}
@media (min-width: 1024px) {
  .b-geekout__content {
    padding-top: 0;
    width: 50%;
  }
}
.b-geekout__heading {
  font-family: Caveat, sans-serif;
  font-size: var(--font-size-3xl);
  letter-spacing: -0.01em;
  line-height: 1;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .b-geekout__heading {
    font-size: var(--font-size-xxl);
    margin: -160px 0 80px 0;
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .b-geekout__heading {
    margin: 0 0 2rem 0;
    width: 100%;
  }
}
.b-geekout__intro {
  margin-bottom: 3rem;
}
@media (min-width: 1024px) {
  .b-geekout__intro {
    font-size: var(--font-size-l);
  }
}
.b-geekout__ctaHeading {
  text-align: center;
}
@media (min-width: 1024px) {
  .b-geekout__ctaHeading {
    text-align: left;
  }
}
.is-success .b-geekout__subscribe {
  display: none;
}
.b-geekout__success {
  animation: geekoutSuccess var(--duration-medium) cubic-bezier(0, 1, 0.75, 1.5) both;
  background: rgb(var(--color));
  border-radius: var(--border-radius);
  color: #FFF;
  display: none;
  font-weight: 600;
  line-height: 56px;
  padding: 0 14px;
  text-align: center;
  will-change: opacity, transform;
}
.is-success .b-geekout__success {
  display: block;
}

.b-hero {
  --equinox: 0;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1023px) {
  .b-hero {
    padding-top: 0 !important;
  }
}
@media (min-width: 1024px) {
  .b-hero {
    display: flex;
    min-height: 85vh;
  }
}
.b-hero__layers {
  height: 65vw;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  .b-hero__layers {
    height: 60vw;
  }
}
@media (min-width: 1024px) {
  .b-hero__layers {
    height: auto;
    overflow: visible;
    position: static;
  }
}
.b-hero__layers:after {
  background-image: url("/assets/img/hero-fade.png");
  background-repeat: repeat-x;
  background-size: 1px 80px;
  bottom: 0;
  content: "";
  height: 80px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 3;
}
@media (min-width: 768px) {
  .b-hero__layers:after {
    background-image: url("/assets/img/hero-fade-m.png");
    background-repeat: repeat-x;
    background-size: 1px 128px;
    height: 128px;
  }
}
@media (min-width: 1024px) {
  .b-hero__layers:after {
    display: none;
  }
}
.b-hero__bg, .b-hero__fg {
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  will-change: transform;
}
@media (min-width: 1024px) {
  .b-hero__bg, .b-hero__fg {
    height: 100%;
  }
}
.b-hero__bg {
  background-image: url("/assets/img/hero-bg.jpg");
  height: 112.5%;
  transform: translate3d(0, calc(var(--equinox-pos) * -11.11111%), 0);
}
.b-hero__fg {
  background-image: url("/assets/img/hero-fg-tiny.png");
  height: 125%;
  transform: translate3d(0, calc(var(--equinox-pos) * -20%), 0);
}
.b-hero .o-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
}
.b-hero__logo {
  height: 64px;
  position: absolute;
  top: 32px;
}
@media (min-width: 768px) {
  .b-hero__logo {
    height: 88px;
    top: 48px;
  }
}
@media (min-width: 1024px) {
  .b-hero__logo {
    height: 96px;
    position: static;
    top: 0;
  }
}
.b-hero__content {
  position: relative;
  z-index: 4;
}
@media (min-width: 768px) {
  .b-hero__content {
    margin-top: -160px;
    width: 75%;
  }
}
@media (min-width: 1024px) {
  .b-hero__content {
    margin-top: 0;
    width: 50%;
  }
}
.b-hero__heading {
  margin-bottom: 2rem;
}
.b-hero__bio {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .b-hero__bio {
    font-size: var(--font-size-l);
  }
}
@media (min-width: 768px) {
  .b-hero__cta {
    display: flex;
    gap: 8px;
  }
}
.b-hero__ctaSocials {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .b-hero__ctaSocials {
    margin-top: 0;
  }
}
.b-hero__socialLink {
  border: 2px solid rgba(var(--color-1), 0.25);
  border-radius: 50%;
  height: 48px;
  padding: 12px;
  transition: border var(--duration-medium) ease;
  width: 48px;
}
@media (min-width: 1024px) {
  .b-hero__socialLink {
    height: 64px;
    padding: 18px;
    width: 64px;
  }
}
.b-hero__socialLink:hover {
  border-color: rgba(var(--color-1), 1);
}
.b-hero__socialLink:hover .b-hero__socialIcon {
  transform: scale(1.15);
}
.b-hero__socialIcon {
  fill: rgb(var(--color-text));
  height: 20px;
  transition: transform var(--duration-medium) ease;
  width: 20px;
  will-change: transform;
}
@media (min-width: 1024px) {
  .b-hero__socialIcon {
    height: 24px;
    width: 24px;
  }
}

.b-media {
  --equinox: 0;
}
.b-media__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.b-media__body {
  margin-bottom: 2rem;
}
.b-media__player {
  border-radius: 4px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
@media (min-width: 1024px) {
  .b-media__player {
    transform: translate3d(0, calc(var(--equinox) * 15%), 0);
    will-change: transform;
  }
}

.b-outlets__heading {
  margin-bottom: 48px;
  text-align: center;
}
.b-outlets__list {
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  gap: 1.5rem;
}
@media (min-width: 1024px) {
  .b-outlets__list {
    gap: 40px;
  }
}
.b-outlets__logo {
  height: 48px;
}
@media (min-width: 1024px) {
  .b-outlets__logo {
    height: 72px;
  }
}

.b-services__service {
  opacity: 0;
  transform: translate3d(0, 24px, 0);
  transition-duration: 0ms;
  transition-timing-function: ease-out;
}
.is-visible .b-services__service {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-duration: var(--duration-slow);
}
.b-services__icon {
  margin: 0 auto 1.5rem auto;
  width: 64px;
}
@media (min-width: 1024px) {
  .b-services__icon {
    margin: 0 0 1.5rem 0;
  }
}
.b-services__heading {
  margin-bottom: 1.5rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .b-services__heading {
    text-align: left;
  }
}
.b-services__cta {
  margin-top: 4rem;
  text-align: center;
}
.b-services__cta::before {
  background: rgb(var(--color-1));
  content: "";
  display: block;
  height: 3px;
  margin: 0 auto 32px auto;
  width: 80px;
}
.b-services__cta h3 {
  color: rgb(var(--color-text));
}

@media (max-width: 767px) {
  .b-testimonials__testimonials {
    flex-wrap: nowrap;
    overflow: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    scroll-snap-type: x mandatory;
  }
  .b-testimonials__testimonials::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
.b-testimonials__testimonial {
  opacity: 0;
  scroll-snap-align: center;
  transform: translate3d(0, 24px, 0);
  transition-duration: 0ms;
  transition-timing-function: ease-out;
}
@media (max-width: 767px) {
  .b-testimonials__testimonial {
    flex: 0 0 80vw;
    padding-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .b-testimonials__testimonial {
    margin-bottom: 1rem;
  }
}
.is-visible .b-testimonials__testimonial {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-duration: var(--duration-slow);
}
@media (max-width: 767px) {
  .b-testimonials__testimonial:first-child .b-testimonials__testimonialInner {
    border-left: none;
    padding-left: 0;
  }
}
.b-testimonials__testimonialInner {
  border-left: 1px solid rgba(var(--color-1), 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-left: 16px;
}
.b-testimonials__quote {
  margin-bottom: 2rem;
  font-size: var(--font-size-s);
}
@media (min-width: 768px) {
  .b-testimonials__quote {
    font-size: var(--font-size-m);
  }
}
.b-testimonials__author {
  align-items: center;
  display: flex;
  font-size: var(--font-size-s);
  gap: 16px;
}
@media (min-width: 768px) {
  .b-testimonials__author {
    font-size: var(--font-size-m);
  }
}
.b-testimonials__authorPhoto {
  border-radius: 50%;
  height: 64px;
  object-fit: cover;
  width: 64px;
}
@media (min-width: 768px) {
  .b-testimonials__authorPhoto {
    height: 80px;
    width: 80px;
  }
}
.b-testimonials__authorName {
  font-weight: 600;
}
.c-button {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  appearance: none;
  background-color: rgb(var(--color-button));
  border-radius: 24px;
  border: none;
  cursor: pointer;
  color: rgb(var(--color-button-text));
  display: inline-block;
  font-size: var(--font-size-m);
  font-weight: 600;
  height: 48px;
  line-height: 20px;
  padding: 14px 24px;
  text-align: center;
  text-decoration: none;
  transition: background-color var(--duration-slow) cubic-bezier(0.25, 1, 0.25, 1);
  user-select: none;
  white-space: nowrap;
}
@media (min-width: 1024px) {
  .c-button {
    border-radius: 28px;
    height: 56px;
    padding: 18px 40px;
  }
}
.c-button:hover {
  background: rgb(var(--color-button-hover));
  color: rgb(var(--color-bg));
  text-decoration: none;
}
.c-button__icon {
  display: block;
  fill: rgb(var(--color-bg));
  float: left;
  margin-right: 4px;
  position: relative;
}
.c-button--inverted {
  background: rgb(var(--color-bg));
  box-shadow: none;
  color: rgb(var(--color-3));
  font-weight: 600;
}
.c-button--inverted:hover {
  box-shadow: none;
}
.c-button--primary {
  background: rgb(var(--color-3));
  color: rgb(var(--color-bg));
  font-weight: 600;
}
@media (min-width: 1024px) {
  .c-button--large {
    border-radius: 32px;
    font-size: var(--font-size-l);
    height: 64px;
    line-height: 24px;
    padding: 20px 40px;
  }
  .c-button--large .c-button__icon {
    height: 24px;
    margin-right: 12px;
    width: 24px;
  }
}

.c-footer {
  align-items: center;
  background: rgb(var(--color-bg-tint));
  background-image: url("/assets/img/section-glow.png");
  background-position: center bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;
  bottom: 0;
  display: flex;
  height: 256px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 1;
}
@media (min-width: 768px) {
  .c-footer {
    height: 320px;
  }
}
@media (min-width: 768px) {
  .c-footer__split {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
.c-footer__logo {
  height: 56px;
  margin: 0 auto 1rem auto;
}
@media (min-width: 768px) {
  .c-footer__logo {
    height: 64px;
    margin: 0 0 1rem 0;
  }
}
.c-footer__legal {
  font-size: var(--font-size-xxs);
  margin-bottom: 1.5rem;
  opacity: 0.5;
  text-align: center;
}
@media (min-width: 768px) {
  .c-footer__legal {
    font-size: var(--font-size-xs);
    margin-bottom: 0;
    text-align: left;
  }
}
.c-footer__contact {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 1.5rem;
}
@media (min-width: 768px) {
  .c-footer__contact {
    justify-content: flex-start;
  }
}
.c-footer__contactLink:hover .c-footer__contactIcon {
  transform: scale(1.15);
}
.c-footer__contactIcon {
  display: block;
  fill: #FFF;
  height: 24px;
  transition: transform var(--duration-medium) ease;
  width: 24px;
  will-change: transform;
}

.c-formatted a {
  text-decoration: none;
  transition: var(--duration-fast) ease;
}
.c-formatted a:hover {
  color: rgb(var(--color-link));
  box-shadow: inset 0 -1px 0 rgba(var(--color-link), 0.5);
}
.c-formatted p {
  margin-bottom: 1em;
}
.c-formatted p:last-child {
  margin-bottom: 0;
}

@keyframes inputCheckboxCheck {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.c-inputCheckbox {
  min-height: 20px;
  position: relative;
  user-select: none;
}
.c-inputCheckbox__input {
  height: 20px;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 20px;
  z-index: 2;
}
.c-inputCheckbox__label {
  display: block;
  line-height: 20px;
  padding-left: 28px;
  position: relative;
  z-index: 1;
}
.c-inputCheckbox__label::before, .c-inputCheckbox__label::after {
  content: "";
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  transition: var(--duration-fast);
  width: 20px;
}
.c-inputCheckbox__label::before {
  background-color: rgb(var(--color-bg));
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgb(var(--color-input-border));
  display: block;
  will-change: background, border-color, box-shadow;
}
.c-inputCheckbox__label::after {
  animation: inputCheckboxCheck var(--duration-fast) ease both;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><polyline points="4 10 8 14 16 6" fill="none" stroke="%23FFF" stroke-width="2"/></svg>');
  display: none;
  will-change: transform;
}
.c-inputCheckbox__input:active + .c-inputCheckbox__label::before, .c-inputCheckbox__input:checked + .c-inputCheckbox__label::before, .c-inputCheckbox__input:focus + .c-inputCheckbox__label::before {
  box-shadow: inset 0 0 0 2px rgb(var(--color-input-border-focus));
}
.c-inputCheckbox__input:checked + .c-inputCheckbox__label::before {
  background-color: rgb(var(--color-input-border-focus));
}
.c-inputCheckbox__input:checked + .c-inputCheckbox__label::after {
  display: block;
}
.c-inputCheckbox--noLabel .inputCheckbox__label {
  padding-left: 20px;
}

.c-inputText {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  font-variant-ligatures: none;
  font-feature-settings: "tnum" 1;
  appearance: none;
  background-color: var(--color-bg);
  border: 1px solid rgba(var(--color-input-border));
  border-radius: var(--border-radius);
  color: var(--color-text);
  display: block;
  font-size: font-size(m);
  height: 48px;
  line-height: 20px;
  min-width: 0;
  padding: 14px;
  transition: var(--duration-fast);
  width: 100%;
  will-change: box-shadow;
}
.c-inputText:hover {
  border-color: var(--color-input-border-hover);
}
.c-inputText:focus {
  box-shadow: inset 0 0 0 2px var(--color-border-focus);
}
.c-inputText--mono {
  font-family: var(--font-mono);
  letter-spacing: var(--font-mono-spacing);
}
.c-inputText--rounded {
  border-radius: 24px;
  padding-left: 24px;
}

.c-inputWithButton {
  align-items: stretch;
  background: rgb(var(--color-bg));
  border: 1px solid rgb(var(--color-input-border));
  border-radius: var(--border-radius);
  display: flex;
  overflow: hidden;
  position: relative;
  transition: border var(--duration-medium) ease;
}
.c-inputWithButton:focus-within {
  border-color: rgb(var(--color-input-border-focus));
}
.c-inputWithButton input {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  background: transparent;
  border: none;
  box-shadow: none;
  height: 54px;
  flex: 1;
  font-size: var(--font-size-m);
  line-height: 24px;
  min-width: 0;
  padding: 15px 12px;
}
@media (min-width: 1024px) {
  .c-inputWithButton input {
    font-size: 18px;
  }
}
.c-inputWithButton button {
  background: transparent;
  border: none;
  color: rgb(var(--color-link));
  cursor: pointer;
  font-size: var(--font-size-m);
  font-weight: 600;
  height: 54px;
  line-height: 24px;
  padding: 16px 16px 14px;
}
.c-inputWithButton button:focus, .c-inputWithButton button:hover {
  color: rgb(var(--color-link-hover));
}
.c-inputWithButton button:focus {
  padding-bottom: 13px;
  padding-top: 17px;
}

@keyframes modalWindow {
  0% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
.c-modal {
  --color: 0, 204, 204;
  --color-bg: 255, 255, 255;
  --color-input-border: 255, 255, 255;
  --color-input-border-focus: 255, 255, 255;
  --color-link: 0, 204, 204;
  --color-link-hover: 0, 0, 0;
  --color-text: 0, 0, 0;
  --equinox: 0;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  padding: 8px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}
.c-modal__window {
  animation: modalWindow 250ms both cubic-bezier(0.25, 1, 0.25, 1);
  background: rgb(var(--color-1));
  border-radius: var(--border-radius);
  box-shadow: 0 24px 48px rgba(0, 0, 0, 0.25);
  color: rgb(var(--color-text));
  max-height: calc(100vh - 16px);
  max-width: 800px;
  overflow: hidden;
  position: relative;
  width: 100%;
  will-change: transform;
}
@media (min-width: 768px) {
  .c-modal__window {
    display: flex;
    align-items: stretch;
  }
}
.c-modal__image {
  background-position: center;
  background-size: cover;
  height: 50vw;
}
@media (min-width: 768px) {
  .c-modal__image {
    height: auto;
    flex: 0 0 50%;
  }
}
.c-modal__main {
  flex: 0 0 50%;
}
@media (min-width: 768px) {
  .c-modal__main {
    position: relative;
  }
}
.c-modal__hide {
  background: #000;
  border: none;
  color: transparent;
  cursor: pointer;
  height: 48px;
  mask-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><line x1="18" y1="18" x2="30" y2="30" fill="none" stroke="%23000" stroke-miterlimit="10" stroke-width="1.5"/><line x1="30" y1="18" x2="18" y2="30" fill="none" stroke="%23000" stroke-miterlimit="10" stroke-width="1.5"/></svg>');
  mask-size: 100%;
  opacity: 0.75;
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
}
@media (min-width: 768px) {
  .c-modal__hide {
    opacity: 0.5;
  }
}
.c-modal__hide:hover {
  opacity: 1;
}
.c-modal__main {
  padding: 2rem;
}
@media (max-width: 767px) {
  .c-modal__main {
    font-size: var(--font-size-s);
  }
}
.c-modal.is-visible {
  display: flex;
}

.has-modal {
  overflow: hidden;
}

@keyframes modalDeck {
  0% {
    opacity: 0;
    transform: translate3d(0, 8px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes modalDeckDownload {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.c-modalDeck .u-delay {
  animation-name: modalDeck;
  animation-duration: var(--duration-slow);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  will-change: opacity, transform;
}
.c-modalDeck__heading {
  color: rgb(var(--color-text));
  font-size: var(--font-size-l);
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .c-modalDeck__heading {
    width: 75%;
  }
}
.c-modalDeck__text {
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .c-modalDeck__text {
    margin-bottom: 2rem;
  }
}
.c-modalDeck__legal {
  font-size: var(--font-size-xs);
}
.c-modalDeck__subscribe, .c-modalDeck__download {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.is-success .c-modalDeck__subscribe {
  display: none;
}
.c-modalDeck__download {
  animation: modalDeckDownload var(--duration-medium) cubic-bezier(0, 1, 0.75, 1.5) both;
  background: rgb(var(--color-bg));
  border-radius: var(--border-radius);
  display: none;
  height: 56px;
  line-height: 24px;
  padding: 15px 16px;
  will-change: opacity, transform;
}
.is-success .c-modalDeck__download {
  display: block;
}
.c-modalDeck__downloadIcon {
  height: 24px;
  float: left;
  margin-right: 12px;
  width: 24px;
}
.c-modalDeck__downloadLink {
  color: rgb(var(--color-text));
  font-weight: 600;
}
@keyframes navSubmenu {
  0% {
    transform: translate3d(0, -16px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.c-nav {
  --multiplier: 0;
  align-items: center;
  background: rgba(var(--color-1), var(--multiplier));
  color: rgb(var(--color-bg));
  display: flex;
  justify-content: space-between;
  height: 64px;
  left: 0;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
@media (min-width: 768px) {
  .c-nav {
    height: 80px;
  }
}
.c-nav__left {
  align-items: center;
  display: flex;
  position: relative;
  z-index: 2;
}
.c-nav__logo {
  height: 30px;
  min-width: 0;
}
@media (min-width: 768px) {
  .c-nav__logo {
    height: 40px;
  }
}
.c-nav__host {
  align-items: center;
  display: flex;
  font-size: 10px;
  margin-left: 1rem;
  user-select: none;
}
@media (min-width: 768px) {
  .c-nav__host {
    font-size: var(--font-size-xs);
    margin-left: 1.5rem;
  }
}
.c-nav__hostLabel {
  font-weight: 600;
  margin-right: 10px;
}
.c-nav__hostLogo {
  height: 28px;
}
@media (min-width: 768px) {
  .c-nav__hostLogo {
    height: 36px;
  }
}
@media (max-width: 1023px) {
  .c-nav__menu {
    margin-bottom: 2rem;
  }
}
@media (min-width: 1024px) {
  .c-nav__menu {
    align-items: center;
    display: flex;
  }
}
@media (max-width: 1023px) {
  .c-nav__menuItem {
    border-bottom: 1px solid rgba(var(--color-4), 0.15);
  }
}
@media (min-width: 1024px) {
  .c-nav__menuItem {
    margin-right: 24px;
    position: relative;
  }
  .c-nav__menuItem:hover .c-nav__submenu {
    display: block;
  }
}
.c-nav__menuLink {
  color: rgb(var(--color-bg));
  cursor: pointer;
  display: block;
  font-weight: 600;
  line-height: 48px;
  text-decoration: none;
  white-space: nowrap;
}
@media (max-width: 1023px) {
  .c-nav__menuLink {
    font-size: var(--font-size-l);
    padding: 8px 0;
  }
}
@media (max-width: 1023px) {
  .c-nav__submenu {
    padding-bottom: 1rem;
  }
}
@media (min-width: 1024px) {
  .c-nav__submenu {
    animation: navSubmenu 500ms cubic-bezier(0.25, 1, 0.25, 1) both;
    background: rgb(var(--color-bg));
    border-radius: 4px;
    box-shadow: 0 16px 32px rgba(var(--color-1), 0.25);
    display: none;
    left: calc(50% - 96px);
    width: 192px;
    padding: 8px 0;
    position: absolute;
  }
}
@media (min-width: 1024px) {
  .c-nav__submenuItem:not(:last-child) {
    border-bottom: 1px solid rgba(var(--color-2), 0.1);
  }
}
.c-nav__submenuLink {
  display: block;
  text-decoration: none;
}
@media (max-width: 1023px) {
  .c-nav__submenuLink {
    color: rgb(var(--color-4));
    font-size: var(--font-size-l);
    padding: 12px 0 12px 20px;
    position: relative;
  }
  .c-nav__submenuLink::before {
    background: rgb(var(--color-4), 1);
    mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><polyline points="1.5 1 6.5 6 1.5 11" fill="none" stroke="%23000" stroke-miterlimit="10" stroke-width="2"/></svg>');
    mask-repeat: no-repeat;
    content: "";
    display: block;
    height: 16px;
    left: 0;
    position: absolute;
    top: calc(50% - 6px);
    width: 8px;
  }
}
@media (min-width: 1024px) {
  .c-nav__submenuLink {
    color: rgb(var(--color-2));
    font-weight: 600;
    padding: 12px 0;
    text-align: center;
  }
}
.c-nav__submenuLink:hover {
  color: rgb(var(--color-bg));
}
@media (min-width: 1024px) {
  .c-nav__submenuLink:hover {
    color: rgb(var(--color-1));
  }
}
@media (min-width: 1024px) {
  .c-nav__right {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }
}
.c-nav__mobileMenuToggle {
  border-radius: 50%;
  display: block;
  height: 48px;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 48px;
  z-index: 3;
}
@media (min-width: 768px) {
  .c-nav__mobileMenuToggle {
    display: none;
  }
}
.c-nav__mobileMenuToggle div {
  background: rgb(var(--color-bg));
  height: 2px;
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 1px);
  transition: all var(--duration-slow) cubic-bezier(0.25, 1, 0.25, 1);
  width: 20px;
  will-change: opacity, transform;
}
.c-nav__mobileMenuToggle div:nth-child(1) {
  transform: translate3d(0, -6px, 0);
}
.c-nav__mobileMenuToggle div:nth-child(3) {
  transform: translate3d(0, 6px, 0);
}
#navMobileMenuToggle:checked ~ .c-nav .c-nav__mobileMenuToggle div:nth-child(1) {
  transform: rotate(45deg) translate3d(0, 0, 0);
}
#navMobileMenuToggle:checked ~ .c-nav .c-nav__mobileMenuToggle div:nth-child(2) {
  opacity: 0;
  transform: scaleX(0);
}
#navMobileMenuToggle:checked ~ .c-nav .c-nav__mobileMenuToggle div:nth-child(3) {
  transform: rotate(-45deg) translate3d(0, 0, 0);
}
@media (max-width: 1023px) {
  .c-nav__mobileMenu {
    background: rgb(var(--color-1));
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    padding: 64px 24px 40px 24px;
    transform: translate3d(100%, 0, 0);
    transition: transform var(--duration-slow) cubic-bezier(0.25, 1, 0.25, 1);
    width: 100%;
    will-change: transform;
    z-index: 1;
  }
  #navMobileMenuToggle:checked ~ .c-nav .c-nav__mobileMenu {
    transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 1023px) {
  .c-nav .c-button {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .c-nav .c-button {
    height: 40px;
    padding: 10px 24px;
  }
}

#navMobileMenuToggle {
  left: -100px;
  position: absolute;
  top: -100px;
  z-index: 1000;
}

.c-page {
  background: rgb(var(--color-bg));
  box-shadow: 0 0 48px rgba(0, 0, 0, 0.5);
  border: 4px solid rgb(var(--color-bg));
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .c-page {
    border: 0.5rem solid rgb(var(--color-bg));
  }
}
@media (min-width: 1024px) {
  .c-page {
    border: 1.5rem solid rgb(var(--color-bg));
  }
}

.c-section {
  border-radius: 4px;
  padding: 4rem 0;
  margin: 0 auto;
  max-width: 1600px;
  overflow: hidden;
}
.c-section:not(:last-child) {
  margin-bottom: 1.5rem;
}
@media (min-width: 1024px) {
  .c-section {
    border-radius: 8px;
    padding: 7rem 0;
  }
}
.c-section__heading {
  margin-bottom: 4rem;
}
@media (max-width: 1023px) {
  .c-section__heading {
    text-align: center;
  }
}
@media (min-width: 1024px) {
  .c-section__heading--center {
    text-align: center;
  }
}
.c-section__text {
  margin-bottom: 1.5rem;
}
.c-section--tint {
  background: rgb(var(--color-bg-tint));
  background-image: url("/assets/img/section-glow.png");
  background-position: center bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

@keyframes titleWordInner {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.c-title {
  line-height: var(--line-height-s);
}
.c-title::after {
  content: "";
  clear: both;
  display: block;
}
.c-title .word {
  display: block;
  float: left;
  margin-right: 0.25em;
  overflow: hidden;
}
.c-title .word .word__inner {
  display: inline-block;
  line-height: var(--line-height-s);
  transform: translate3d(0, 100%, 0);
}
.c-title .word:nth-child(1) .word__inner {
  animation-delay: calc(1 * 50ms);
}
.c-title .word:nth-child(2) .word__inner {
  animation-delay: calc(2 * 50ms);
}
.c-title .word:nth-child(3) .word__inner {
  animation-delay: calc(3 * 50ms);
}
.c-title .word:nth-child(4) .word__inner {
  animation-delay: calc(4 * 50ms);
}
.c-title .word:nth-child(5) .word__inner {
  animation-delay: calc(5 * 50ms);
}
.c-title .word:nth-child(6) .word__inner {
  animation-delay: calc(6 * 50ms);
}
.c-title .word:nth-child(7) .word__inner {
  animation-delay: calc(7 * 50ms);
}
.c-title .word:nth-child(8) .word__inner {
  animation-delay: calc(8 * 50ms);
}
.c-title .word:nth-child(9) .word__inner {
  animation-delay: calc(9 * 50ms);
}
.c-title .word:nth-child(10) .word__inner {
  animation-delay: calc(10 * 50ms);
}
.c-title .word:nth-child(11) .word__inner {
  animation-delay: calc(11 * 50ms);
}
.c-title .word:nth-child(12) .word__inner {
  animation-delay: calc(12 * 50ms);
}
.c-title .word:nth-child(13) .word__inner {
  animation-delay: calc(13 * 50ms);
}
.c-title .word:nth-child(14) .word__inner {
  animation-delay: calc(14 * 50ms);
}
.c-title .word:nth-child(15) .word__inner {
  animation-delay: calc(15 * 50ms);
}
.c-title .word:nth-child(16) .word__inner {
  animation-delay: calc(16 * 50ms);
}
.c-title .word:nth-child(17) .word__inner {
  animation-delay: calc(17 * 50ms);
}
.c-title .word:nth-child(18) .word__inner {
  animation-delay: calc(18 * 50ms);
}
.c-title .word:nth-child(19) .word__inner {
  animation-delay: calc(19 * 50ms);
}
.c-title .word:nth-child(20) .word__inner {
  animation-delay: calc(20 * 50ms);
}
.c-title.is-visible .word__inner {
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-name: titleWordInner;
  animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
}

.u-delay {
  --delay: 100ms;
}
.u-delay:nth-child(1) {
  animation-delay: calc(0 * var(--delay));
  transition-delay: calc(0 * var(--delay));
}
.u-delay:nth-child(2) {
  animation-delay: calc(1 * var(--delay));
  transition-delay: calc(1 * var(--delay));
}
.u-delay:nth-child(3) {
  animation-delay: calc(2 * var(--delay));
  transition-delay: calc(2 * var(--delay));
}
.u-delay:nth-child(4) {
  animation-delay: calc(3 * var(--delay));
  transition-delay: calc(3 * var(--delay));
}
.u-delay:nth-child(5) {
  animation-delay: calc(4 * var(--delay));
  transition-delay: calc(4 * var(--delay));
}
.u-delay:nth-child(6) {
  animation-delay: calc(5 * var(--delay));
  transition-delay: calc(5 * var(--delay));
}
.u-delay:nth-child(7) {
  animation-delay: calc(6 * var(--delay));
  transition-delay: calc(6 * var(--delay));
}
.u-delay:nth-child(8) {
  animation-delay: calc(7 * var(--delay));
  transition-delay: calc(7 * var(--delay));
}
.u-delay:nth-child(9) {
  animation-delay: calc(8 * var(--delay));
  transition-delay: calc(8 * var(--delay));
}
.u-delay:nth-child(10) {
  animation-delay: calc(9 * var(--delay));
  transition-delay: calc(9 * var(--delay));
}

.u-m-none {
  margin-bottom: 0 !important;
}

.u-m-xxs {
  margin-bottom: 0.125rem !important;
}

.u-m-xs {
  margin-bottom: 0.25rem !important;
}

.u-m-s {
  margin-bottom: 0.5rem !important;
}

.u-m-m {
  margin-bottom: 1rem !important;
}

.u-m-l {
  margin-bottom: 1.5rem !important;
}

.u-m-xl {
  margin-bottom: 2rem !important;
}

.u-m-xxl {
  margin-bottom: 3rem !important;
}

.u-m-3xl {
  margin-bottom: 4rem !important;
}

.u-m-4xl {
  margin-bottom: 5rem !important;
}

.u-m-5xl {
  margin-bottom: 6rem !important;
}

.u-m-6xl {
  margin-bottom: 7rem !important;
}

@media (min-width: 320px) {
  .xs\:u-m-none {
    margin-bottom: 0 !important;
  }

  .xs\:u-m-xxs {
    margin-bottom: 0.125rem !important;
  }

  .xs\:u-m-xs {
    margin-bottom: 0.25rem !important;
  }

  .xs\:u-m-s {
    margin-bottom: 0.5rem !important;
  }

  .xs\:u-m-m {
    margin-bottom: 1rem !important;
  }

  .xs\:u-m-l {
    margin-bottom: 1.5rem !important;
  }

  .xs\:u-m-xl {
    margin-bottom: 2rem !important;
  }

  .xs\:u-m-xxl {
    margin-bottom: 3rem !important;
  }

  .xs\:u-m-3xl {
    margin-bottom: 4rem !important;
  }

  .xs\:u-m-4xl {
    margin-bottom: 5rem !important;
  }

  .xs\:u-m-5xl {
    margin-bottom: 6rem !important;
  }

  .xs\:u-m-6xl {
    margin-bottom: 7rem !important;
  }
}
@media (min-width: 375px) {
  .s\:u-m-none {
    margin-bottom: 0 !important;
  }

  .s\:u-m-xxs {
    margin-bottom: 0.125rem !important;
  }

  .s\:u-m-xs {
    margin-bottom: 0.25rem !important;
  }

  .s\:u-m-s {
    margin-bottom: 0.5rem !important;
  }

  .s\:u-m-m {
    margin-bottom: 1rem !important;
  }

  .s\:u-m-l {
    margin-bottom: 1.5rem !important;
  }

  .s\:u-m-xl {
    margin-bottom: 2rem !important;
  }

  .s\:u-m-xxl {
    margin-bottom: 3rem !important;
  }

  .s\:u-m-3xl {
    margin-bottom: 4rem !important;
  }

  .s\:u-m-4xl {
    margin-bottom: 5rem !important;
  }

  .s\:u-m-5xl {
    margin-bottom: 6rem !important;
  }

  .s\:u-m-6xl {
    margin-bottom: 7rem !important;
  }
}
@media (min-width: 768px) {
  .m\:u-m-none {
    margin-bottom: 0 !important;
  }

  .m\:u-m-xxs {
    margin-bottom: 0.125rem !important;
  }

  .m\:u-m-xs {
    margin-bottom: 0.25rem !important;
  }

  .m\:u-m-s {
    margin-bottom: 0.5rem !important;
  }

  .m\:u-m-m {
    margin-bottom: 1rem !important;
  }

  .m\:u-m-l {
    margin-bottom: 1.5rem !important;
  }

  .m\:u-m-xl {
    margin-bottom: 2rem !important;
  }

  .m\:u-m-xxl {
    margin-bottom: 3rem !important;
  }

  .m\:u-m-3xl {
    margin-bottom: 4rem !important;
  }

  .m\:u-m-4xl {
    margin-bottom: 5rem !important;
  }

  .m\:u-m-5xl {
    margin-bottom: 6rem !important;
  }

  .m\:u-m-6xl {
    margin-bottom: 7rem !important;
  }
}
@media (min-width: 1024px) {
  .l\:u-m-none {
    margin-bottom: 0 !important;
  }

  .l\:u-m-xxs {
    margin-bottom: 0.125rem !important;
  }

  .l\:u-m-xs {
    margin-bottom: 0.25rem !important;
  }

  .l\:u-m-s {
    margin-bottom: 0.5rem !important;
  }

  .l\:u-m-m {
    margin-bottom: 1rem !important;
  }

  .l\:u-m-l {
    margin-bottom: 1.5rem !important;
  }

  .l\:u-m-xl {
    margin-bottom: 2rem !important;
  }

  .l\:u-m-xxl {
    margin-bottom: 3rem !important;
  }

  .l\:u-m-3xl {
    margin-bottom: 4rem !important;
  }

  .l\:u-m-4xl {
    margin-bottom: 5rem !important;
  }

  .l\:u-m-5xl {
    margin-bottom: 6rem !important;
  }

  .l\:u-m-6xl {
    margin-bottom: 7rem !important;
  }
}
@media (min-width: 1280px) {
  .xl\:u-m-none {
    margin-bottom: 0 !important;
  }

  .xl\:u-m-xxs {
    margin-bottom: 0.125rem !important;
  }

  .xl\:u-m-xs {
    margin-bottom: 0.25rem !important;
  }

  .xl\:u-m-s {
    margin-bottom: 0.5rem !important;
  }

  .xl\:u-m-m {
    margin-bottom: 1rem !important;
  }

  .xl\:u-m-l {
    margin-bottom: 1.5rem !important;
  }

  .xl\:u-m-xl {
    margin-bottom: 2rem !important;
  }

  .xl\:u-m-xxl {
    margin-bottom: 3rem !important;
  }

  .xl\:u-m-3xl {
    margin-bottom: 4rem !important;
  }

  .xl\:u-m-4xl {
    margin-bottom: 5rem !important;
  }

  .xl\:u-m-5xl {
    margin-bottom: 6rem !important;
  }

  .xl\:u-m-6xl {
    margin-bottom: 7rem !important;
  }
}
.u-text-align-center {
  text-align: center;
}

.u-text-align-left {
  text-align: left;
}

.u-text-align-right {
  text-align: right;
}

.u-text-case-lower {
  text-transform: lowercase;
}

.u-text-case-upper {
  text-transform: uppercase;
}

.u-text-color-hc {
  color: var(--color-text-hc);
}

.u-text-color-lc {
  color: var(--color-text-lc);
}
.u-text-color-lc a {
  color: var(--color-text-lc);
  text-decoration: underline;
}
.u-text-color-lc a:hover {
  color: var(--color-link-hover);
}

.u-text-mask {
  display: inline;
  margin: 0 2px;
  filter: blur(2px);
  opacity: 0.5;
}

.u-text-mono {
  font-family: var(--font-mono);
}

.u-text-trunc {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.u-text-size-s {
  font-size: var(--font-size-s);
}

.u-hide {
  display: none;
}

.u-hideEmpty:empty {
  display: none;
}

@media (max-width: 319px) {
  .xs\:u-show {
    display: none;
  }
}
@media (min-width: 320px) {
  .xs\:u-hide {
    display: none;
  }
}
@media (max-width: 374px) {
  .s\:u-show,
.m\:u-show,
.l\:u-show,
.xl\:u-show {
    display: none;
  }
}
@media (min-width: 375px) {
  .s\:u-hide {
    display: none;
  }
}
@media (max-width: 767px) {
  .m\:u-show,
.l\:u-show,
.xl\:u-show {
    display: none;
  }
}
@media (min-width: 768px) {
  .m\:u-hide {
    display: none;
  }
}
@media (max-width: 1023px) {
  .l\:u-show,
.xl\:u-show {
    display: none;
  }
}
@media (min-width: 1024px) {
  .l\:u-hide {
    display: none;
  }
}
@media (max-width: 1279px) {
  .xl\:u-show {
    display: none;
  }
}
@media (min-width: 1280px) {
  .xl\:u-hide {
    display: none;
  }
}