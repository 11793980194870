@keyframes inputCheckboxCheck {
    0% { transform: scale(0.5); }
    100% { transform: scale(1); }
}

.c-inputCheckbox {
    min-height: 20px;
    position: relative;
    user-select: none;

    &__input {
        height: 20px;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 20px;
        z-index: 2;
    }

    &__label {
        display: block;
        line-height: 20px;
        padding-left: 28px;
        position: relative;
        z-index: 1;

        &::before,
        &::after {
            content: '';
            cursor: pointer;
            height: 20px;
            left: 0;
            position: absolute;
            top: 0;
            transition: var(--duration-fast);
            width: 20px;
        }

        &::before {
            background-color: rgb(var(--color-bg));
            border-radius: 3px;
            box-shadow: inset 0 0 0 1px rgb(var(--color-input-border));
            display: block;
            will-change: background, border-color, box-shadow;
        }

        &::after {
            animation: inputCheckboxCheck var(--duration-fast) ease both;
            background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><polyline points="4 10 8 14 16 6" fill="none" stroke="%23FFF" stroke-width="2"/></svg>');
            display: none;
            will-change: transform;
        }

        .c-inputCheckbox__input:active + &,
        .c-inputCheckbox__input:checked + &,
        .c-inputCheckbox__input:focus + & {
            &::before {
                box-shadow: inset 0 0 0 2px rgb(var(--color-input-border-focus));
            }
        }

        .c-inputCheckbox__input:checked + & {
            &::before {
                background-color: rgb(var(--color-input-border-focus));
            }

            &::after {
                display: block;
            }
        }
    }

    &--noLabel {
        .inputCheckbox__label {
            padding-left: 20px;
        }
    }
}