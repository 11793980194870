.c-section {
    border-radius: 4px;
    padding: space(3xl) 0;
    margin: 0 auto;
    max-width: 1600px;
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: space(l);
    }

    @include screen-min(l) {
        border-radius: 8px;
        padding: space(6xl) 0;
    }

    &__heading {
        margin-bottom: space(3xl);
        
        @include screen-max(l) {
            text-align: center;
        }

        &--center {
            @include screen-min(l) {
                text-align: center;
            }
        }
    }

    &__text {
        margin-bottom: space(l);
    }

    &--tint {
        background: rgb(var(--color-bg-tint));
        background-image: url('/assets/img/section-glow.png');
        background-position: center bottom;
        background-size: 100% auto;
        background-repeat: no-repeat;
    }
}