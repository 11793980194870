body {
    @include font-1;
    background: rgb(var(--color-bg));
    color: rgb(var(--color-text));
    line-height: var(--line-height-m);
    padding-bottom: 256px;
    text-rendering: optimizelegibility;

    @include screen-min(m) {
        padding-bottom: 320px;
    }

    &.is-loading,
    &.is-loading * {
        cursor: wait;
        pointer-events: none;
    }
}