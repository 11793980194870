.b-testimonials {
    &__testimonials {
        @include screen-max(m) {
            flex-wrap: nowrap;
            overflow: auto;
            padding-left: space(l);
            padding-right: space(l);
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
        }
    }

    &__testimonial {
        opacity: 0;
        scroll-snap-align: center;
        transform: translate3d(0, 24px, 0);
        transition-duration: 0ms;
        transition-timing-function: ease-out;

        @include screen-max(m) {
            flex: 0 0 80vw;
            padding-bottom: space(m);
        }

        @include screen-min(m) {
            margin-bottom: space(m);
        }

        .is-visible & {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition-duration: var(--duration-slow);
        }

        &:first-child {
            @include screen-max(m) {
                .b-testimonials__testimonialInner {
                    border-left: none;
                    padding-left: 0;
                }
            }
        }
    }

    &__testimonialInner {
        border-left: 1px solid rgba(var(--color-1), 0.5);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-left: 16px;
    }

    &__quote {
        margin-bottom: space(xl);
        font-size: var(--font-size-s);
        
        @include screen-min(m) {
            font-size: var(--font-size-m);
        }
    }

    &__author {
        align-items: center;
        display: flex;
        font-size: var(--font-size-s);
        gap: 16px;

        @include screen-min(m) {
            font-size: var(--font-size-m);
        }
    }

    &__authorPhoto {
        border-radius: 50%;
        height: 64px;
        object-fit: cover;
        width: 64px;

        @include screen-min(m) {
            height: 80px;
            width: 80px;
        }
    }

    &__authorName {
        font-weight: 600;
    }

    &__authorCompany {
    }
}