@keyframes modalDeck {
    0% { opacity: 0; transform: translate3d(0, 8px, 0); }
    100% { opacity: 1; transform: translate3d(0, 0, 0); }
}

@keyframes modalDeckDownload {
    0% { opacity: 0; transform: scale(0.5); }
    100% { opacity: 1; transform: scale(1); }
}

.c-modalDeck {
    .u-delay {
        animation-name: modalDeck;
        animation-duration: var(--duration-slow);
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
        will-change: opacity, transform;
    }

    &__heading {
        color: rgb(var(--color-text));
        font-size: var(--font-size-l);
        margin-bottom: space(m);

        @include screen-min(m) {
            width: 75%;
        }
    }

    &__text {
        margin-bottom: space(l);

        @include screen-min(m) {
            margin-bottom: space(xl);
        }        
    }

    &__legal {
        font-size: var(--font-size-xs);
    }

    &__subscribe,
    &__download {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    &__subscribe {
        .is-success & {
            display: none;
        }
    }

    &__download {
        animation: modalDeckDownload var(--duration-medium) cubic-bezier(0, 1, 0.75, 1.5) both;
        background: rgb(var(--color-bg));
        border-radius: var(--border-radius);
        display: none;
        height: 56px;
        line-height: 24px;
        padding: 15px 16px;
        will-change: opacity, transform;

        .is-success & {
            display: block;
        }
    }

    &__downloadIcon {
        height: 24px;
        float: left;
        margin-right: 12px;
        width: 24px;
    }

    &__downloadLink {
        color: rgb(var(--color-text));
        font-weight: 600;

        &:hover {
            // color: rgb(var(--color));
        }
    }
}