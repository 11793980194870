.o-wrapper {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1440px;
    width: 100%;

    @include screen-min(m) {
        padding-left: 48px;
        padding-right: 48px;
    }

    &__bleed {
        margin-left: -24px;
        margin-right: -24px;

        @include screen-min(m) {
            margin-left: -48px;
            margin-right: -48px;
        }

        @include screen-min(l) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &--narrow {
        max-width: 800px;
    }
}