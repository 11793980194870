.b-services {
    &__service {
        opacity: 0;
        transform: translate3d(0, 24px, 0);
        transition-duration: 0ms;
        transition-timing-function: ease-out;

        .is-visible & {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition-duration: var(--duration-slow);
        }
    }

    &__icon {
        margin: 0 auto space(l) auto;
        width: 64px;
        
        @include screen-min(l) {
            margin: 0 0 space(l) 0;
        }
    }

    &__heading {
        margin-bottom: space(l);
        text-align: center;

        @include screen-min(l) {
            text-align: left;
        }
    }

    &__cta {
        margin-top: space(3xl);
        text-align: center;

        &::before {
            background: rgb(var(--color-1));
            content: '';
            display: block;
            height: 3px;
            margin: 0 auto 32px auto;
            width: 80px;
        }

        h3 {
            color: rgb(var(--color-text));
        }
    }
}