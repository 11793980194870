.c-inputWithButton {
    align-items: stretch;
    background: rgb(var(--color-bg));
    border: 1px solid rgb(var(--color-input-border));
    border-radius: var(--border-radius);
    display: flex;
    overflow: hidden;
    position: relative;
    transition: border var(--duration-medium) ease;

    &:focus-within {
        border-color: rgb(var(--color-input-border-focus));
    }

    input {
        @include font-1;
        background: transparent;
        border: none;
        box-shadow: none;
        height: 54px;
        flex: 1;
        font-size: var(--font-size-m);
        line-height: 24px;
        min-width: 0;
        padding: 15px 12px;

        @include screen-min(l) {
            font-size: 18px;
        }
    }

    button {
        background: transparent;
        border: none;
        color: rgb(var(--color-link));
        cursor: pointer;
        font-size: var(--font-size-m);
        font-weight: 600;
        height: 54px;
        line-height: 24px;
        padding: 16px 16px 14px;

        &:focus,
        &:hover {
            color: rgb(var(--color-link-hover));
        }

        &:focus {
            padding-bottom: 13px;
            padding-top: 17px;
        }
    }
}