.c-page {
    background: rgb(var(--color-bg));
    box-shadow: 0 0 48px rgba(0, 0, 0, 0.5);
    border: 4px solid rgb(var(--color-bg));
    position: relative;
    z-index: 2;

    @include screen-min(m) {
        border: space(s) solid rgb(var(--color-bg));
    }

    @include screen-min(l) {
        border: space(l) solid rgb(var(--color-bg));
    }
}