.c-formatted {
    a {
        text-decoration: none;
        transition: var(--duration-fast) ease;

        &:hover {
            color: rgb(var(--color-link));
            box-shadow: inset 0 -1px 0 rgba(var(--color-link), 0.5);
        }
    }

    p {
        margin-bottom: 1em;

        &:last-child {
            margin-bottom: 0;
        }
    }
}