.c-footer {
    align-items: center;
    background: rgb(var(--color-bg-tint));
    background-image: url('/assets/img/section-glow.png');
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
    bottom: 0;
    display: flex;
    height: 256px;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 1;

    @include screen-min(m) {
        height: 320px;
    }

    &__split {        
        @include screen-min(m) {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }

    &__logo {
        height: 56px;
        margin: 0 auto space(m) auto;
        
        @include screen-min(m) {
            height: 64px;
            margin: 0 0 space(m) 0;
        }
    }

    &__legal {
        font-size: var(--font-size-xxs);
        margin-bottom: space(l);
        opacity: 0.5;
        text-align: center;

        @include screen-min(m) {
            font-size: var(--font-size-xs);
            margin-bottom: 0;
            text-align: left;
        }
    }

    &__contact {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        gap: space(l);

        @include screen-min(m) {
            justify-content: flex-start;
        }
    }

    &__contactLink {
        &:hover {
            .c-footer__contactIcon {
                transform: scale(1.15);
            }
        }
    }

    &__contactIcon {
        display: block;
        fill: #FFF;
        height: 24px;
        transition: transform var(--duration-medium) ease;
        width: 24px;
        will-change: transform;
    }
}