@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/Metropolis-Light.woff2') format('woff2'),
         url('/assets/fonts/Metropolis-Light.woff') format('woff');
}

@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/Metropolis-Regular.woff2') format('woff2'),
         url('/assets/fonts/Metropolis-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/Metropolis-RegularItalic.woff2') format('woff2'),
         url('/assets/fonts/Metropolis-RegularItalic.woff') format('woff');
}

@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/Metropolis-SemiBold.woff2') format('woff2'),
         url('/assets/fonts/Metropolis-SemiBold.woff') format('woff');
}

// @font-face {
//     font-family: 'Metropolis';
//     font-style: normal;
//     font-weight: 700;
//     src: url('/assets/fonts/Metropolis-Bold.woff2') format('woff2'),
//          url('/assets/fonts/Metropolis-Bold.woff') format('woff');
// }