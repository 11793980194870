.b-hero {
    --equinox: 0;
    overflow: hidden;
    position: relative;
    
    @include screen-max(l) {
        padding-top: 0 !important;
    }

    @include screen-min(l) {
        display: flex;
        min-height: 85vh;
    }

    &__layers {
        height: 65vw;
        overflow: hidden;
        position: relative;

        @include screen-min(m) {
            height: 60vw;
        }

        @include screen-min(l) {
            height: auto;
            overflow: visible;
            position: static;
        }

        &:after {
            background-image: url('/assets/img/hero-fade.png');
            background-repeat: repeat-x;
            background-size: 1px 80px;
            bottom: 0;
            content: '';
            height: 80px;
            left: 0;
            position: absolute;
            width: 100%;
            z-index: 3;

            @include screen-min(m) {
                background-image: url('/assets/img/hero-fade-m.png');
                background-repeat: repeat-x;
                background-size: 1px 128px;
                height: 128px;
            }

            @include screen-min(l) {
                display: none;
            }
        }
    }

    &__bg,
    &__fg {
        background-position: top right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        will-change: transform;

        @include screen-min(l) {
            height: 100%;
        }
    }

    &__bg {
        background-image: url('/assets/img/hero-bg.jpg');
        height: 112.5%;
        transform: translate3d(0, calc(var(--equinox-pos) * -11.11111%), 0);
    }

    &__fg {
        background-image: url('/assets/img/hero-fg-tiny.png');
        height: 125%;
        transform: translate3d(0, calc(var(--equinox-pos) * -20%), 0);
    }

    .o-wrapper {
        align-items: flex-start;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        z-index: 2;
    }

    &__logo {
        height: 64px;
        position: absolute;
        top: 32px;
        
        @include screen-min(m) {
            height: 88px;
            top: 48px;
        }

        @include screen-min(l) {
            height: 96px;
            position: static;
            top: 0;
        }
    }

    &__content {
        position: relative;
        z-index: 4;

        @include screen-min(m) {
            margin-top: -160px;
            width: 75%;
        }

        @include screen-min(l) {
            margin-top: 0;
            width: 50%;
        }
    }

    &__heading {
        margin-bottom: space(xl);
    }

    &__bio {
        margin-bottom: space(xl);

        @include screen-min(m) {
            font-size: var(--font-size-l);
        }
    }

    &__cta {
        @include screen-min(m) {
            display: flex;
            gap: 8px;
        }

    }

    &__ctaSocials {
        display: flex;
        gap: 8px;
        margin-top: 16px;

        @include screen-min(m) {
            margin-top: 0;
        }
    }

    &__socialLink {
        border: 2px solid rgba(var(--color-1), 0.25);
        border-radius: 50%;
        height: 48px;
        padding: 12px;
        transition: border var(--duration-medium) ease;
        width: 48px;

        @include screen-min(l) {
            height: 64px;
            padding: 18px;
            width: 64px;
        }

        &:hover {
            border-color: rgba(var(--color-1), 1);

            .b-hero__socialIcon {
                transform: scale(1.15);
            }
        }
    }

    &__socialIcon {
        fill: rgb(var(--color-text));
        height: 20px;
        transition: transform var(--duration-medium) ease;
        width: 20px;
        will-change: transform;

        @include screen-min(l) {
            height: 24px;
            width: 24px;
        }
    }
}