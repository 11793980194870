.c-inputText {
    @include font-1;
    @include ligatures(none);
    @include tabular-numbers;
    appearance: none;
    background-color: var(--color-bg);
    border: 1px solid rgba(var(--color-input-border));
    border-radius: var(--border-radius);
    color: var(--color-text);
    display: block;
    font-size: font-size(m);
    height: 48px;
    line-height: 20px;
    min-width: 0;
    padding: 14px;
    transition: var(--duration-fast);
    width: 100%;
    will-change: box-shadow;

    &:hover {
        border-color: var(--color-input-border-hover);
    }

    &:focus {
        box-shadow: inset 0 0 0 2px var(--color-border-focus);
    }

    &--mono {
        @include font-mono;
    }

    &--rounded {
        border-radius: 24px;
        padding-left: 24px;
    }
}